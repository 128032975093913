@import '../../../stylesheets/basics/layout-variables';

$publication-banner-logo-small-path: '/design/source/images/asc-logo.svg';

// Color Variables
$color-primary: #009ee0;
$text-color: #323232;
$light-gray: #f0f0f0;
$dark-gray: #a6a6a6;
$marketing-green: #7ab637;
$marketing-green-light: #edf6e2;
$color-primary-dark: darken($color-primary, 10%);

$menu-neutral-light: $light-gray;
$menu-neutral-dark: #CCCCCC;

// Typography Variables
$font-primary: 'Open Sans', sans-serif;

$text-s: 0.8125rem;
$text-m: 1rem;
$text-l: 1.25rem;
$text-xl: 1.563rem;
$text-xxl: 1.953rem;
$text-xxxl: 2.441rem;

$text-regular: 400;
$text-bold: 700;

// Space Variables

// Vertical Variables
$spacer-v-xxxs: 0.5rem;
$spacer-v-xxs: 0.75rem;
$spacer-v-xs: 1rem;
$spacer-v-s: 1.25rem;
$spacer-v-m: 1.5rem;
$spacer-v-l: 1.75rem;
$spacer-v-xl: 2rem;
$spacer-v-xxl: 2.5rem;
$spacer-v-xxxl: 3rem;

// Horizontal Variables
$spacer-h-xxxs: 0.5rem;
$spacer-h-xxs: 0.75rem;
$spacer-h-xs: 1rem;
$spacer-h-s: 1.25rem;
$spacer-h-m: 1.5rem;

// Misc Variables
$nav-border-top: .3125rem solid $color-primary;
$nav-border-bottom: .0625rem solid $dark-gray;
$nav-item-padding: .6rem;
$nav-item-margins: .2rem;
$links-item-padding: .8125rem;
$footer-link-border: .0625rem solid #c7c1b9;
$footer-logo-border: .125rem solid $color-primary;
$logo-border-responsive: 3px solid $color-primary;
$paywall-logo-width: 15rem;